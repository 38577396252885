import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	// matchHeight

	$('.cta').matchHeight();
	$('.home-cta').matchHeight();
	$('.mobile-home-cta').matchHeight();
	$('.cb-col').matchHeight();
	$('.team .text-wrap').matchHeight();

	$('.page-template-template-ontdek-ip .cb-col').matchHeight({ remove: true });
	$('.page-template-template-levenslangleren .cb-col').matchHeight({ remove: true });

	$.fn.matchHeight._afterUpdate = function(event, groups) {
    	// do something after all updates are applied
    	var section;
    	section = window.location.hash;

		$('html, body').animate({
		    scrollTop: $(section).offset().top
		});
	}

	// Change navbar class on scroll

	var $siteTop = $(".intro-wrap");

	$siteTop.waypoint(function () {
		$(".navbar").toggleClass("active");
	},
	{
		offset: '-10px'
	});

	// Display search bar after click icon

	$(".search .icon").click(function() {
		$(".search .search-bar").toggleClass('search-active').focus();
	});

	// Open pop-up - Word partner

	$(".activate-wordpartner").click(function() {
		$(".pop-up-join-ip").addClass('pop-up-active').focus();
	});

	// Open pop-up - Nieuwsbrief

	$(".activate-nieuwsbrief").click(function() {
		$(".pop-up-nieuwsbrief").addClass('pop-up-active').focus();
	});

	// Close pop-up

	$(".pop-up .close-button").click(function() {
		$(".pop-up-container").removeClass('pop-up-active').focus();
	});

	// IP voor jou - Calculate sidebar image height

	$( ".page-template-template-ip-voor-jou .cb-wrap" ).each(function() {

		var colHeight = $(this).find(".sidebar-col").height();
		var infoHeight = $(this).find(".sidebar-col .sidebar-info").height();

		$(this).find(".sidebar-col .sidebar-img").height(colHeight - infoHeight);

	});

    // IP voor jou - Activate first item

    if (location.href.indexOf("#") == -1) {
    	$( ".page-template-template-ip-voor-jou .cb-wrap:first-of-type" ).addClass('active');
    }

    $(".page-template-template-ip-voor-jou .intro-wrap .cta").click(function() {
    	$( ".page-template-template-ip-voor-jou .cb-wrap:first-of-type" ).removeClass('active');
    });

    // Levenslang leren - Activate first item

    if (location.href.indexOf("#") == -1) {
    	$( ".page-template-template-levenslangleren .cb-wrap .content-block .content-col .block-content:first-of-type" ).addClass('active');
    }

    $(".page-template-template-levenslangleren .cb-wrap .content-block .sidebar-col ul li a").click(function() {
    	$(this).parents('.cb-wrap').find('.content-col .block-content:first-of-type').removeClass('active');
    	$(this).parents('.cb-wrap').siblings('.cb-wrap').find('.content-col .block-content:visible').addClass('active');

    	$(this).parent().siblings().children().click(function() {
    		$(this).parents('.cb-wrap').find('.content-col .block-content').removeClass('active');
    	});
    });


	// Scroll to search results

    $('html, body').animate({
        scrollTop: $('#zoekresultaten').offset().top
    }, 'slow');

    // Clone filter title

    //$('.page-template-template-publicaties .bedrijven-wrap .content-block .sidebar-col .bedrijven-filters .clusters-filter input[type="radio"]:checked + label').clone().appendTo('.filter-title');

    $(".page-template-template-publicaties .bedrijven-wrap .content-block .sidebar-col .bedrijven-filters .clusters-filter input").click(function(){
    	$(this).clone().appendTo('.page-template-template-publicaties .bedrijven-wrap .content-block .sidebar-col .bedrijven-filters .clusters-filter input');
    });

});